import React from "react"
import PropTypes from "prop-types"
import Vimeo from "@u-wave/react-vimeo"
import merge from "lodash/merge"

const defaultStyles = {
  height: "100%",
  width: "100%",
}

const Video = ({ video, style }) => {
  const _style = React.useMemo(() => merge({}, defaultStyles, style), [style])
  return <Vimeo video={video} style={_style} responsive />
}

Video.propTypes = {
  video: PropTypes.string,
  style: PropTypes.object,
}

Video.defaultProps = {
  style: {},
}

export default Video
